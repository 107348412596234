import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import BioPic from '../../images/The_Hook.jpeg';
import LinkedIn from '../../icons/linkedin-logo.png';
import GitHub from '../../icons/github.png'
import ResumeIcon from '../../icons/portfolio.png'
import MyResume from '../../documents/CaitlinO\'Callaghan_Resume_Oct_4_2021.pdf'
import Ireland from '../../icons/Ireland.png'
import Canada from '../../icons/Canada.png'
import USA from '../../icons/USA.png'

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    @media (max-width: 768px) {
    flex-direction: column;
    margin: 80px;
    }
`

const Intro = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 360px;
    @media (max-width: 768px) {
      width: 300px;
    }
`

const Picture = styled.img.attrs({
    src: BioPic})`
    width: 250px;
    margin: 20px;
    margin: 50px;
`

const MyName = styled.div`
    font-size: 64px;
    font-weight: 600;
    @media (max-width: 768px) {
        font-size: 50px;
    }
`

const Icon = styled.img`
    width: 35px;
    margin: 5px;
`
const IconWrapper = styled.a`
    text-decoration: none;
`

const LinkedInLink = 'https://www.linkedin.com/in/caitlin-ocallaghan/'
const GitHubLink = 'https://github.com/CaitlinOCallaghan'
const USACitizen = 'https://www.uscis.gov/us-citizenship'
const CanadaCitizen = 'https://www.canada.ca/en/immigration-refugees-citizenship/services/canadian-citizenship.html'
const IrelandCitizen = 'https://www.citizensinformation.ie/en/'

class Home extends Component {
    render () {
        return(
            <SectionWrapper id='home'>
                <Picture/>
                <Intro>
                    <MyName>Caitlin O'Callaghan</MyName>
                    <p>
                        Hi, my name is Caitlin, and I am a biomedical engineering student at the University of Waterloo. 
                        I love software, biomedical research, running, soccer, and combat sports.
                    </p>
                    <div>
                        <ReactTooltip class='custom-tooltip' place='bottom' effect='solid' textColor='white' font-weight='bold' font-size='15px' backgroundColor='#1e024f'/>
                        <IconWrapper href={MyResume} data-tip="Resume" target="_blank">
                            <Icon src={ResumeIcon}/>
                        </IconWrapper>
                        <IconWrapper href={LinkedInLink} data-tip="LinkedIn" target="_blank">
                            <Icon src={LinkedIn} />
                        </IconWrapper>
                        <IconWrapper href={GitHubLink} data-tip="GitHub" target="_blank">
                            <Icon src={GitHub} />
                        </IconWrapper>
                        <IconWrapper href={USACitizen} data-tip="American Citizenship" target="_blank">
                            <Icon src={USA} />
                        </IconWrapper>
                        <IconWrapper href={CanadaCitizen} data-tip="Canadian Citizenship" target="_blank">
                            <Icon src={Canada} />
                        </IconWrapper>
                        <IconWrapper href={IrelandCitizen} data-tip="Irish Citizenship" target="_blank">
                            <Icon src={Ireland} />
                        </IconWrapper> 
                    </div>
                </Intro>
            </SectionWrapper>
        )
    }
}

export default Home