import UW_Logo from '../../icons/UW_Logo.png'
import NU_Logo from '../../icons/NU_Logo.png'
import UIUC_Logo from '../../icons/UIUC_Logo.png'
import UIC_Logo from '../../icons/UIC_Logo.png'


export default {
    UW: {
        affiliation: "University of Waterloo",
        lab: "MME",
        description: `I investigated the error in the calculation of oscillatory shear index from Doppler ultrasound readings of blood velocity. 
        For the analysis, I constructed a MATLAB program to estimate wall shear stress in arteries.`,
        startDate: "January",
        endDate: "December",
        year: "2018",
        tools: ['MATLAB', 'Blood Flow'],
        image: UW_Logo
    }, 
    NW: {
        affiliation: "Northwestern University",
        lab: "MME",
        description: `Within the Northwestern Feinberg School of Medicine, Department of Cardiology, I computationally quantified structural differences between healthy and heart failure induced cardiomyocytes.`,
        startDate: "August 2016",
        endDate: "April",
        year: "2017",
        tools: ['MATLAB', 'Cardiology', 'Heart Failure'],
        image: NU_Logo
    },
    UIUC: {
        affiliation: "University of Illinois at Urbana-Champaign",
        lab: "MME",
        description: `I investigated the response of drug resistant breast cancer cells to combination therapies, 
        specifically the role of nuclear transport protein XPO1 in Tamoxifen resistance.`,
        startDate: "June",
        endDate: "August",
        year: "2016",
        tools: ['Cell culture', 'Drug development', 'Breast Cancer'],
        image: UIUC_Logo
    },
    UIC: {
        affiliation: "University of Illinois at Chicago",
        lab: "MME",
        description: `My research focused on the improvement of bacterial resistant properties of Polymethyl Methacrylate (PMMA) 
        for dental appliances through polymer surface treatments.`,
        startDate: "August 2015",
        endDate: "April",
        year: "2016",
        tools: ['Polymer Chemistry', 'Biomaterials'],
        image: UIC_Logo
    }
}