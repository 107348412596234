import React, { Component } from 'react'
import styled from 'styled-components'
import MenuItem from './MenuItem'

const SectionWrapper = styled.div`
    top: 0px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100vw;
    height: 60px;
    background-color: #1e024f;
    z-index: 1000;
`

class MenuBar extends Component {
    renderMenuItems = () => {
        const text = ['Home', 'Experience', 'Publications', 'Research', 'Personal']
        const link = ['#home', '#experience', '#publications', '#research', '#personal']
        var menuItems = []
        for (var i = 0; i<text.length; i++) {
            menuItems.push(<MenuItem link={link[i]} text={text[i]} key={text[i]}/>)
        }
        return menuItems
    }

    render () {
        return (
            <SectionWrapper>
                {this.renderMenuItems()}
            </SectionWrapper>
        )
    }
}

export default MenuBar