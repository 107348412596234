import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center !important;
        margin: 30px;
        align-items: center;
    }
`

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    @media (max-width: 768px) {
        flex-direction: column;
        width: auto;
    }
`

const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    justify-content: flex-start;
    max-width: 500px;
`

const Affiliation = styled.div`
    font-size: 30px;
    line-space: 100%;
    margin-bottom: 10px;
`

const Date = styled.div`
    front-size: 12px;      
    margin-bottom: 5px; 
`

const LogoContainer = styled.div`
    marign-right: 10px;
    width: 125px;
`

const Logo = styled.img`
    width: 90px;
    marign-right: 10px;
`

class ResearchCell extends Component {
    renderToolsList = (tools) => {
        var toolsList = [];
        for (var i = 0; i < tools.length; i++) {
            toolsList.push(
                <li key={i}>{tools[i]}</li>
            )
        }
        return toolsList
    }
    render () {
        return (
            <Container>
                <LogoContainer>
                    <Logo src={this.props.data.image}/>
                </LogoContainer>
                <LeftSection>
                    <Affiliation>{this.props.data.affiliation}</Affiliation>
                    <Date>{this.props.data.startDate} to {this.props.data.endDate} {this.props.data.year}</Date>
                    {this.renderToolsList(this.props.data.tools)}
                </LeftSection>
                <RightSection>
                    <div>{this.props.data.description}</div>
                </RightSection>
            </Container>
        )
    }
}

export default ResearchCell 