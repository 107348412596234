import React, { Component } from 'react'
import styled from 'styled-components'
import ExperienceCell from './ExperienceCell'
import ExperienceData from './ExperienceData'

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90vw;
`
const SectionTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    color: rgb(0, 0, 0);
    margin: 40px 40px 10px 50px;
    transition: all 0.3s ease-out 0s;
    @media (max-width: 768px) {
        margin: 40px 40px 10px 10px;
    }
`

const CellsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

class Experience extends Component {
    renderExperienceCells = () => {
        var experienceArray = []
        const companies = ['Facebook2021', 'StreamShark', 'Facebook2020', 'Airtime', 'TribalScale', 'Pathway']
        for( var i=0; i<companies.length; i++) {
            experienceArray.push(
                <ExperienceCell data={ExperienceData[companies[i]]} key={i}/>
            )
        }
        return experienceArray
    }

    render() {
        return (
            <SectionWrapper id='experience'>
                <SectionTitle>Experience</SectionTitle>
                <CellsWrapper>
                    {this.renderExperienceCells()}
                </CellsWrapper>
            </SectionWrapper>
        )
    }
}

export default Experience
