import React, { Component } from 'react'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Text = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: white;
    transition: 0.3s ease-out;
    padding: 20px;
    text-decoration: none;
    &:hover {
    color: #c6afed;
    transition: 0.3s ease-out;
    border: none;
    text-align: center;
    text-overflow: clip;
    text-decoration: none;
    }
    a {
    text-decoration: none;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        padding-left: 3px;
        padding-right: 3px;
    }
`

class MenuItem extends Component {
    render () {
        return (
            <div>
                <AnchorLink href={this.props.link} offset={"30vh"}>
                    <Text>{this.props.text}</Text>
                </AnchorLink>
            </div>
        )
    }
}

export default MenuItem