import React, { Component } from 'react'
import styled from 'styled-components'

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20vh;
    @media (max-width: 768px) {
    flex-direction: column;
    margin: 80px;
    }
`

class Footer extends Component {
    render () {
        return(
            <SectionWrapper id='footer'>
               Portfolio last updated: September 2021
            </SectionWrapper>
        )
    }
}

export default Footer