import Oahu from '../../images/Oahu.jpg'
import MuscleBeach from '../../images/Muscle_Beach.jpg'
import IntramuralChamps from '../../images/Intramural_Champs.JPG'
import MuayThai from '../../images/MuayThai.jpg'
import Caltrain from '../../images/Caltrain.jpg'
import Euro from '../../images/Euro.JPG'
import Chicago from '../../images/Chicago.jpg'
import Lanikai from '../../images/Lanikai.JPG'
import ThirdStreet from '../../images/3rd_Street_Boxing.jpg'

export default {
    0: {
        image: IntramuralChamps,
        caption: `Waterloo intramural champions summer 2019!`,
        isVert: false
    },
    1: {
        image: MuscleBeach,
        caption: `Muscle Beach in Los Angeles`,
        isVert: false
    },
    2: {
        image: Caltrain,
        caption: `Early morning Caltrain`,
        isVert: false
    },
    3: {
        image: MuayThai,
        caption: `Muay Thai, Toronto 2018`,
        isVert: true
    },
    4: {
        image: Oahu,
        caption: `Sunset over Diamond Head`,
        isVert: false
    },
    5: {
        image: Euro,
        caption: `Euro 2016: Ireland vs Italy`,
        isVert: true
    },
    6: {
        image: Chicago,
        caption: `Chicago Skyline`,
        isVert: false
    },
    7: {
        image: Lanikai,
        caption: `Lanikai, Oahu`,
        isVert: false
    },
    8: {
        image: ThirdStreet,
        caption: `Boxing, San Francisco 2021`,
        isVert: true
    }

}