import React, { Component } from 'react'
import styled from 'styled-components'

const Cell = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: solid;
    border-color: #1e024f;
    border-width: thick;
    width: 350px;
    margin: 20px;
    a {
        text-decoration: none;
      }
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        width: 275px;
    }
`
const Link = styled.a`
    tex-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 350px;
    @media (max-width: 768px) {
        width: auto;
    }
`

const Preview = styled.img`
    height: 200px;
    width: autopx;
    margin: 10px;
`
const Content = styled.div`
    min-height: 110px;
    margin: 10px;
    color: black;
    a {
        text-decoration: none;
      }
`

const Title = styled.span`
    font-weight: bold;
`

const Journal = styled.span`
    font-style: italic;
`

class PublicationCell extends Component {
    render () {
        return (
            <Cell>
                <Link href={this.props.data.link} target="_blank">
                    <Preview src={this.props.data.image}></Preview>
                    <Content>
                        <Title>{this.props.data.title}. </Title>
                        <Journal>{this.props.data.journal}. </Journal>
                        <span>{this.props.data.pubDate}.</span>
                    </Content>
                </Link>
            </Cell>
        )
    }
}

export default PublicationCell