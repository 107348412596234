import React, { Component } from 'react'
import styled from 'styled-components'
import PersonalData from './PersonalData'

const SectionWrapper = styled.div`
    display: flex; 
    flex-direction: column;
    width: 90vw;
`

const SectionTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    color: rgb(0, 0, 0);
    margin: 40px 40px 0px 50px;
    transition: all 0.3s ease-out 0s;
    @media (max-width: 768px) {
        margin: 40px 40px 10px 10px;
    }
`
const Statement = styled.div`
    max-width: 800px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
`

const GalleryWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 90vw;
`

const Gallery = styled.div`
    position: relative;
    transition: height 480ms ease-out 0s;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    max-width: 900px;
`

const ImageContainer = styled.div`
    width: 250px;
    margin: 10px;
    flex-wrap: wrap;
    flex-direction: column;
    display: flex;
`

const ImageHoriz = styled.img`
    max-height: 170px;    
    max-width: 250px;  
    margin-bottom: 50px;
    margin-top: 30px;
`

const ImageVert = styled.img`
    max-width: 170px;
    max-height: 250px;
    padding-left: 40px;
    margin-bottom: 50px;
    margin-top: 30px;
`
const Caption = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;
    margin-top: 3px;
    text-align: center;
`

class Personal extends Component {
    renderImages = () => {
        var imageList = []
        const dataKeys = [0, 1, 2, 3, 4, 5, 6, 7, 8]
        for(var i = 0; i<dataKeys.length; i++) {
            if(PersonalData[i].isVert) {
                imageList.push(
                    <ImageContainer key={i}>
                        <ImageVert src={PersonalData[i].image}/>
                        <Caption>{PersonalData[i].caption}</Caption>
                    </ImageContainer>
                )
            }
            else {
                imageList.push(
                    <ImageContainer key={i}>
                        <ImageHoriz src={PersonalData[i].image}/>
                        <Caption>{PersonalData[i].caption}</Caption>
                    </ImageContainer>
                )
            }
            
        }
        return(imageList)
    }
    render () {
        return(
            <SectionWrapper id='personal'>
                <SectionTitle>Personal</SectionTitle>
                <GalleryWrapper>
                    <Statement>
                    </Statement>
                    <Gallery>
                        {this.renderImages()}
                    </Gallery>
                </GalleryWrapper>
            </SectionWrapper>
        )
    }
}

export default Personal