import React from 'react';
import './App.css';
import Experience from './components/Experience/Experience'
import Publications from './components/Publications/Publications'
import Research from './components/Research/Research'
import Home from './components/Home/Home'
import Personal from './components/Personal/Personal'
import MenuBar from './components/Menu/MenuBar'
import Footer from './components/Footer/Footer'

function App() {
  return (
    <div className="App">
        <MenuBar/>
        <Home/>
        <Experience/>
        <Publications/>
        <Research/>
        <Personal/>
        <Footer/>
    </div>
  );
}

export default App;
