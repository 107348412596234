import React, { Component } from 'react'
import styled from 'styled-components'
import Redirect from '../../icons/Redirect.png'

const Cell = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
      }
`
const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
`
const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    justify-content: flex-start;
    max-width: 500px;
`

const CompanyContainer = styled.div`
    display: flex;
`

const Company = styled.div`
    font-size: 30px;
    line-space: 100%;
    margin-bottom: 10px;
`

const Date = styled.div`
    margin-bottom: 5px;       
`

const Position = styled.div`
    font-size: 20px;
    line-space: 100%;
    margin-bottom: 10px;
`

const Description = styled.div`

`
const TechStackContainer = styled.div`
`

const LinkContainer = styled.a`
`

const LinkIcon = styled.img`
    width: 15px;
    margin-left: 5px;
`

class ExperienceCell extends Component {
    renderTechStack = (techStack) => {
        var techStackList = [];
        for (var i = 0; i < techStack.length; i++) {
            techStackList.push(
                <li key={i}>{techStack[i]}</li>
            )
        }
        return techStackList
    }
    render () {
        return (
    <Cell>
        <LeftSection>
            <CompanyContainer>
                <Company>{this.props.data.company}</Company>
                <LinkContainer href={this.props.data.website} target="_blank">
                    <LinkIcon src={Redirect}/>
                </LinkContainer>
            </CompanyContainer>
            <Date>{this.props.data.startDate} to {this.props.data.endDate} {this.props.data.year}</Date>
            <TechStackContainer>
                {this.renderTechStack(this.props.data.techStack)}
            </TechStackContainer>
        </LeftSection>
        <RightSection>
            <Position>{this.props.data.position}</Position>
            <Description>{this.props.data.description}</Description>
        </RightSection>
    </Cell>
    )
    }
}

export default ExperienceCell 