export default {
  Facebook2021: {
    key: 'Facebook2021',
    company: 'Facebook',
    position: 'Software Engineering Intern',
    description: `This summer, I have been working on the VR Video team within Facebook Reality Labs. 
    I implmented low latency DASH (LL-DASH) streaming in Shaka Packager, Google's open sourced media packaging tool.
    I also added LL-DASH support to Shaka Streamer, a streaming tool that wraps FFMPEG and Shaka Packager.
    Internally, I was able to productionize and ship the LL-DASH feature on a Facebook product.`,
    startDate: 'May',
    endDate: 'September',
    year: '2021',
    techStack: ['C++', 'MPEG-DASH', 'Low Latency'],
    website: 'https://about.fb.com/company-info/'
  },  
  StreamShark: {
        key: 'StreamShark',
        company: 'StreamShark',
        position: 'Software Engineering Intern',
        description: `At StreamShark, I architected and implemented an end-to-end video streaming pipeline that supports live and video-on-demand streaming.
        The pipeline used only open-sourced software, eliminating paid services and reducing company costs.`,
        startDate: 'January',
        endDate: 'April',
        year: '2021',
        techStack: ['Java', 'FFMPEG', 'SRT', 'AWS'],
        website: 'https://streamshark.io/'
    },
    Facebook2020: {
        key: 'Facebook2020',
        company: 'Facebook',
        position: 'Software Engineering Intern',
        description: `At Facebook, I worked under two teams: FBOSS Network Infrastructure and AR/VR Video Streaming. 
        As a member of the FBOSS team, I coded features for production switches in Facebook's data centers.
        With the AR/VR department I created, and patented, a system for video encoding with reactive bitrate.`,
        startDate: 'May',
        endDate: 'September',
        year: '2020',
        techStack: ['C++', 'CMake', 'Multi-threading'],
        website: 'https://about.fb.com/company-info/'
    },
    Airtime: {
        key: 'Airtime',
        company: 'Airtime',
        position: 'Software Engineering Intern',
        description: `At Airtime I worked as a member of the media engineering team, focusing on video and audio engineering.
        I specifically worked on implementing a real-time, objective video quality analysis framework for video streams. `,
        startDate: 'September',
        endDate: 'December',
        year: '2019',
        techStack: ['C++', 'CMake', 'Jenkins', 'Process Synchronization'],
        website: 'https://airtime.com/'
    },
    TribalScale: {
        key: 'TribalScale',
        company: 'TribalScale',
        position: 'Agile Software Engineering Intern',
        description: `Practicing agile engineering, test driven development, and pair-programming, I was a member of
        TribalScale's engineering team. I built a full-stack project management website, created an AmazonTV POC for 
        iHeartRadio.com, and developed a VR iOS application.`,
        startDate: 'January',
        endDate: 'April',
        year: '2019',
        techStack: ['React.js', 'Kotlin', 'SpringBoot', 'iOS', 'GraphQL', 'Redux'],
        website: 'https://www.tribalscale.com/'
    },
    Pathway: {
        key: 'Pathway Commons',
        company: 'Pathway Commons',
        position: 'Software Development Intern',
        description: `Pathway Commons serves to represent biological data in a meaningful manner. I created the
         Enrichment Analysis web application and designed its user interface. 
        The application takes an input of genes and returns a network of 
        relevant pathways. A live production version can be found at PathwayCommons.org.`,
        startDate: 'May',
        endDate: 'August',
        year: '2018',
        techStack: ['Javascript', 'React.js', 'Node.js'],
        website: 'https://www.pathwaycommons.org/'
    }
}
