import React, { Component } from 'react'
import styled from 'styled-components'
import PublicationCell from './PublicationCell'
import PublicationData from './PublicationData'

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90vw;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
    }
`

const SectionTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    color: rgb(0, 0, 0);
    margin: 40px 40px 10px 50px;
    transition: all 0.3s ease-out 0s;
    @media (max-width: 768px) {
        margin: 40px 40px 10px 10px;
    }
`
const PublicationWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 90vw;
`

const PublicationGrid = styled.div`
    position: relative;
    transition: height 480ms ease-out 0s;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    margin: 20px;
    max-width: 800px;
`

class Publications extends Component {
    renderPublicationCells = () => {
        var publicationArray = []
        const papers = ['Airtime', 'UofT', 'UIUC', 'Northwestern']
        for( var i=0; i<papers.length; i++) {
            publicationArray.push(
                <PublicationCell data={PublicationData[papers[i]]} key={i}/>
            )
        }
        return publicationArray
    }

    render () {
        return (
        <SectionWrapper id='publications'>
            <SectionTitle>Publications</SectionTitle>
            <PublicationWrapper>
                <PublicationGrid>
                   {this.renderPublicationCells()}
                </PublicationGrid>
            </PublicationWrapper>
        </SectionWrapper>
        )
    }
}

export default Publications