import VideoQuality from '../../images/VQA_Workflow.png'
import enrichment from '../../images/PathwayCommonsEnrichment.jpg'
import UIUC_Research from '../../images/UIUC_Research.PNG'
import NU_Research from '../../images/NU_Research.png'

export default {
    Airtime: {
        key: 'Airtime',
        title: 'Objective Video Quality Analysis at Airtime',
        journal: 'Airtime Blog',
        pubDate: 'Dec. 2019',
        description:"Video Quality Analysis",
        image: VideoQuality,
        link: `https://blog.airtime.com/objective-video-quality-analysis-at-airtime-6543dac7bc1b`
    },
    UofT: {
        key: 'UofT',
        title: 'Pathway Commons 2019 Update: integration, analysis and exploration of pathway data',
        journal: 'Nucleic Acids Research',
        pubDate: 'Oct. 2019',
        description:"Computational Biology Tools",
        image: enrichment,
        link: `https://academic.oup.com/nar/article/48/D1/D489/5606621`
    },
    UIUC: {
        key: 'UIUC',
        title: `Combined targeting of Estrogen Receptor alpha and XPO1 prevent Akt activation, remodel metabolic pathways and induce\
                autophagy to overcome tamoxifen resistance`,
        journal: 'Cancers',
        pubDate: 'April 2019',
        description:"Breast Cancer",
        image: UIUC_Research,
        link: `https://www.mdpi.com/2072-6694/11/4/479`
    },
    Northwestern: {
        key: 'Northwestern',
        title: 'Triggered Calcium Waves Induce Depolarization of Resting Potential and Action Potential Prolongation in Dog Atrial Myocytes',
        journal: 'Circulation: Arrhythmia and Electrophysiology',
        pubDate: 'May 2020',
        description:"Heart failure",        
        image: NU_Research,
        link: `https://pubmed.ncbi.nlm.nih.gov/32433891`
    }
}