import React, { Component } from 'react'
import styled from 'styled-components'
import ResearchData from './ResearchData'
import ResearchCell from './ResearchCell'

const SectionWrapper = styled.div`
    display: flex; 
    flex-direction: column;
    width: 90vw;
`
const SectionTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    color: rgb(0, 0, 0);
    margin: 40px 40px 10px 50px;
    transition: all 0.3s ease-out 0s;
    @media (max-width: 768px) {
        margin: 40px 40px 10px 10px;
    }
`
const CellsWrapper = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        width:90vw;
    }
`

class Research extends Component {
    renderResearchCells = () => {
        var researchArray = []
        const locations = ['UW', 'NW', 'UIUC', 'UIC']
        for( var i=0; i<locations.length; i++) {
            researchArray.push(
                <ResearchCell data={ResearchData[locations[i]]} key={i}/>
            )
        }
        return researchArray
    }

    render () {
        return (
        <SectionWrapper id='research'>
            <SectionTitle>Research</SectionTitle>
            <CellsWrapper>{this.renderResearchCells()}</CellsWrapper>
        </SectionWrapper>
        )
    }
}

export default Research